<template>
	<div id="register-client">
		<div class="screen-title">クライアント登録</div>
		<b-container fluid>
			<div>
				<validation-observer
					class="w-100"
					v-slot="{ handleSubmit }"
					ref="formValidator"
				>
					<b-form class="w-100" role="form" @submit.prevent="handleSubmit(onRegister)">
						<b-row class="mx-0 d-flex row-gap-20">
							<card class="my-0 w-100">
								<div class="card-title">基本情報</div>
								<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											placeholder="会社/屋号"
											name="会社/屋号"
											v-model="model.clientName"
											:rules="{ required: true }"
											label="会社/屋号"
											:inputRequired="true"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											class="mb-3"
											placeholder="メールアドレス"
											name="メールアドレス"
											:rules="{
												required: true,
												email: true,
												validate: [
													[clientId, model.mailAddress],
													'このメールアドレスが存在しました。別のメールアドレスをご記入ください',
													checkEmailValidate,
												],
											}"
											v-model="model.mailAddress"
											label="メールアドレス"
											:inputRequired="true"
											:inputClasses="'custom-input-class'"
											:labelClasses="'custom-form-input-label'"
										>
										</base-input>
									</div>
								</b-row>
								<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
									<div
										class="col-md col-xs-12 px-0 res_margin_bottom d-flex col-gap-20"
									>
										<!-- <base-input
											alternative
											placeholder="郵便番号"
											name="郵便番号"
											:value="model.postcode"
											label="郵便番号"
											type="text"
											@keydown="onKeyDownPostalCode"
											@input="onPostalCodeInput"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
										>
										</base-input> -->

										<!-- An added Area -->
										<base-input
											alternative
											placeholder="郵便番号"
											name="郵便番号"
											v-model="model.postcodeAddressJapan"
											@blur="onBlurPostcode"
											label="郵便番号"
											type="text"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
											:noteError="errorNoteMsg"
										>
										</base-input>
										<!-- An added Area -->

										<base-input
											label="都道府県"
											name="都道府県"
											:labelClasses="'custom-form-input-label'"
										>
											<el-select
												placeholder="選択なし"
												v-model="provinceSelect"
												filterable
												clearable
											>
												<el-option
													v-for="option in provinceOptions"
													:key="option.value"
													:label="option.label"
													:value="option"
												>
												</el-option>
											</el-select>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0">
										<base-input
											alternative
											placeholder="住所"
											name="住所"
											v-model="model.address"
											label="住所"
											:labelClasses="'custom-form-input-label'"
											:inputClasses="'custom-input-class'"
										>
										</base-input>
									</div>
								</b-row>
								<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											class="mb-3"
											placeholder="担当者名"
											name="担当者名"
											v-model="model.managerName"
											label="担当者名"
											:inputClasses="'custom-input-class'"
											:labelClasses="'custom-form-input-label'"
											unit="様"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											placeholder="担当者名フリガナ"
											name="担当者名フリガナ"
											v-model="model.managerNameInFurigana"
											label="担当者名フリガナ"
											:inputClasses="'custom-input-class'"
											:labelClasses="'custom-form-input-label'"
											unit="様"
										>
										</base-input>
									</div>
								</b-row>
								<b-row class="custom__mx-3 d-flex pb-27 col-gap-30">
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											class="mb-3"
											placeholder="電話番号"
											name="電話番号"
											v-model="model.phoneNumber"
											label="電話番号"
											type="text"
											@keydown="onKeyDown"
											@blur="checkPhoneNumber"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
										>
										</base-input>
									</div>
									<div class="col-md col-xs-12 px-0 res_margin_bottom">
										<base-input
											alternative
											class="mb-3"
											placeholder="FAX番号"
											name="FAX番号"
											v-model="model.faxNumber"
											label="FAX番号"
											type="text"
											@keydown="onKeyDown"
											@blur="checkFaxNumber"
											:numberCaption="true"
											:labelClasses="'custom-form-input-label'"
										>
										</base-input>
									</div>
								</b-row>
							</card>
							<card class="my-0 w-100">
								<div class="card-title">備考</div>
								<textarea
									class="form-control"
									type="text"
									v-model="model.clientRemark"
									style="resize: none"
									rows="5"
								></textarea>
							</card>
						</b-row>
						<b-row class="my-4 mx-0 px-0">
							<b-col class="w-100 d-flex justify-content-center">
								<button native-type="submit" class="customButton customButton__submit">
									登録
								</button>
							</b-col>
						</b-row>
					</b-form>
				</validation-observer>
			</div>
		</b-container>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			:single-btn="notiSingleBtn"
			:redirect="redirectLink"
			@on-click="onAccept"
		/>
	</div>
</template>
<script>
import { Select, Option, DatePicker } from 'element-ui';
import ClientService from '@/services/client.service';
import zipAddressService from '@/services/zip_address.service';
import UserService from '@/services/user.service';

import clientPaginationMixin from '@/mixin/clientPaginationMixin';
import autoAddressMixin from '@/mixin/autoAddressMixin';
import postcodeMixin from '@/mixin/postcodeMixin';

import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';
import CommonConst from '@/util/const';
import {
	isFullWidth,
	isHalfWidth,
	convertToHalfWidth,
	convertToFullWidth,
} from '@/util/formatNumber';

export default {
	name: 'RegisterClient',
	mixins: [clientPaginationMixin, autoAddressMixin, postcodeMixin],
	components: {
		[DatePicker.name]: DatePicker,
		[Select.name]: Select,
		[Option.name]: Option,
		NotiModal,
	},
	data() {
		return {
			notiModalId: 'user-edit-noti',
			notiContent: '',
			notiSingleBtn: false,
			redirectLink: '',
			model: {
				clientName: '',
				postcode: '',
				address: undefined,
				clientRemark: undefined,
				managerName: undefined,
				managerNameInFurigana: undefined,
				phoneNumber: undefined,
				faxNumber: undefined,
				mailAddress: '',
				contractDate: undefined,
				// An added area
				postcodeAddressJapan: '',
				// An added area
			},
			clientId: '',
			provinceSelect: '',
			provinceOptions: [],
			loginUserId: '',
			alertMessage: '',
			showAlert: false,
			successType: 'info',
			errorType: 'danger',
			alertType: 'info',
		};
	},
	watch: {
		async 'model.postcode'(newPostcode, oldPostcode) {
			if (!newPostcode) return;

			if (newPostcode.length === CommonConst.POST_CODE_LENGTH) {
				const response = await zipAddressService.get(newPostcode);
				const address = response.data;

				this.provinceSelect = this.findProvinceSelect(address.pref);
				this.model.address = address.fullAddress;
			} else if (newPostcode.length > CommonConst.POST_CODE_LENGTH) {
				this.model.postcode = oldPostcode;
			} else {
				this.provinceSelect = {};
				this.model.address = '';
			}
		},
	},
	methods: {
		checkPhoneNumber() {
			if (this.model.phoneNumber) {
				this.model.phoneNumber = this.model.phoneNumber.replaceAll('-', '');
			}
		},
		checkFaxNumber() {
			if (this.model.faxNumber) {
				this.model.faxNumber = this.model.faxNumber.replaceAll('-', '');
			}
		},
		checkPostCodeNumber() {
			if (this.model.postcode) {
				this.model.postcode = this.model.postcode.replaceAll('-', '');
			}
		},
		onKeyDown(event) {
			const acceptedKeys = [
				'Backspace',
				'Delete',
				'ArrowLeft',
				'ArrowRight',
				'Minus',
				'NumpadSubtract',
			];
			if (
				(isNaN(Number(event.key)) && !acceptedKeys.includes(event.code)) ||
				event.code == 'Space'
			) {
				event.preventDefault();
			}
		},
		findProvinceSelect(provinceName) {
			return this.provinceOptions.find((province) => province.label === provinceName);
		},

		async onRegister() {
			let [clientDuplicated, error] = await await ClientService.search({
				client_name: this.model.clientName,
			});
			if (error) {
				console.log(error);
			}
			this.notiSingleBtn = false;
			if (clientDuplicated ? clientDuplicated.length > 0 : false) {
				this.notiContent = '既にこの会社/屋号で登録されています。\n登録しますか？';
				this.$bvModal.show(`${this.notiModalId}`);
			} else {
				this.notiContent = '登録しますか？';
				this.$bvModal.show(`${this.notiModalId}`);
			}
		},

		async registerClient() {
			this.model.postcodeAddressJapan = this.convertToHalfWidth(
				this.model.postcodeAddressJapan
			);
			let newClientInfo = {
				address: this.model.address,
				client_name: this.model.clientName,
				email_address: this.model.mailAddress,
				fax_number: this.model.faxNumber,
				phone_number: this.model.phoneNumber,
				// postal_code: this.model.postcode,
				postal_code: this.model.postcodeAddressJapan,
				province_id: parseInt(this.provinceSelect.value),
				remark: this.model.clientRemark,
				update_user: this.loginUserId,
				responsible_id: this.loginUserId,
				responsible_name: this.model.managerName,
				responsible_name_furigana: this.model.managerNameInFurigana,
			};
			let [response, error] = await ClientService.register(newClientInfo);
			if (response) {
				this.redirectLink = 'searchclient';
				this.notiSingleBtn = true;
				this.notiContent = '登録しました。';
				this.$bvModal.show(`${this.notiModalId}`);
			} else {
				this.showAlert = true;
				this.alertMessage =
					'エラーが発生したため、クライアントが登録できない。もう一度お願いします。';
				this.alertType = this.errorType;
			}
		},

		onAccept() {
			this.registerClient();
		},

		async checkEmailValidate(userId, email) {
			let [response, error] = await await UserService.checkExists({
				email_address: email,
			});
			if (error) {
				console.log(error);
			}
			if (response) {
				if (response.id == userId) {
					return true;
				}
				return !response.exists;
			}
		},
	},
};
</script>
